import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Axios from "axios"

const Navigation = ({data}) => {

    const title = data.site.siteMetadata.title

    return (        
        <Navbar expand="md" style={{backgroundColor:"#fff", boxShadow:'0 4px 12px 0 rgba(0, 0, 0, 0.05)'}}>   
            <Container>
                <Navbar.Brand href="/">{title}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title="Recent Blogs" id="basic-nav-dropdown">
                            {data.allMdx.edges.map(blog => {                
                                return(
                                    <NavDropdown.Item href={blog.node.fields.slug} key={blog.node.fields.slug}>
                                        {blog.node.frontmatter.title}
                                    </NavDropdown.Item>
                                )
                            })}
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/">All blogs</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Form inline>
                        {/* <Button variant="outline-info">Subscribe</Button> */}
                        <SubscribeForm buttonLabel="Subscribe"></SubscribeForm>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        )

}

const Header = () => {
    return(
        <StaticQuery
            query={graphql`
                query {
                    site {
                        siteMetadata{
                            title
                            description
                        }
                    }
                    allMdx (
                        limit: 5
                        sort: {order:DESC, fields: [frontmatter___date]}
                    ) {
                        edges {
                            node {
                                id
                                frontmatter {
                                    date
                                    title
                                    path
                                }
                                fields {
                                    slug
                                }
                            }
                        }
                    }
                }
            `}
            render={data => <Navigation data={data} />}
        />
    )
}

//pop up a subscription modal when the user presses Subscribe
class SubscribeForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            showSuccess: false,
            showError: false,
            isLoading: false,
            fullName: "",
            emailAddress: "",
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubscription = this.handleSubscription.bind(this);
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const id = target.id

        this.setState({
          [id]: value,
        })
      }

    handleSubscription = event => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {    
            event.stopPropagation();
        }

        this.setState({isLoading:true});

        let t = this;

        Axios.post(
            'https://o4yzd8hqi9.execute-api.ap-southeast-2.amazonaws.com/default/createSubscriber',
        { 
            "email": this.state.emailAddress, "name": this.state.fullName
        }).then(function (response) {
            t.setState({isLoading:false});
            if(!response.data.error){
                //error from MailerLite
                t.setState({showSuccess:true});
            } else {
                //success case
                t.setState({showError:true});    
            }
            // console.log(response.data);
        })
        .catch(function (error) {
            //error sending the httprequest
            t.setState({isLoading:false});
            t.setState({showError:true});
            console.log(error);
        });
    }

    handleClose = event => {
        this.setState({showModal: false});
        this.setState({showError:false});
        this.setState({showSuccess:false});
    } 
    
    handleShow = event => {
        this.setState({showModal: true});
        this.setState({showError:false});
        this.setState({showSuccess:false});
    }

    render() {
        return (
            <>
                <Button variant="outline-info" onClick={this.handleShow}>{this.props.buttonLabel}</Button>

                <Modal show={this.state.showModal} onHide={this.handleClose} >
                    <Form onSubmit={this.handleSubscription}>
                        <Modal.Header closeButton>
                            <Modal.Title>New posts in your inbox</Modal.Title>
                        </Modal.Header>
                        <Alert show={this.state.showSuccess} variant="success">
                            Success. Thanks for subscribing!
                        </Alert>
                        <Alert show={this.state.showError} variant="danger">
                            Something went wrong. Please try again.
                        </Alert>
                        <Modal.Body> 
                            <Form.Group controlId="fullName">
                                <Form.Label>Full name</Form.Label>
                                <Form.Control type="text" placeholder="Enter your name" onChange={this.handleInputChange}/>
                            </Form.Group>
                            <Form.Group controlId="emailAddress">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" onChange={this.handleInputChange}/>
                                <Form.Text className="text-muted">
                                    I'll only use your email to send you new posts.
                                </Form.Text>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button 
                            variant="info" 
                            type="submit"
                            disabled={this.state.isLoading}
                        >
                            {this.state.isLoading ? 'Subscribing you…' : 'Subscribe'}
                        </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        )
    }
}

export default Header
