import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import favicon16 from "../assets/favicon-16.png";
import favicon32 from "../assets/favicon-32.png";
import favicon64 from "../assets/favicon-64.png";

const SiteMetadata = ({ pathname, pagetitle }) => {
    const {
        site: {
        siteMetadata: { siteUrl, title },
        },
    } = useStaticQuery(graphql`
        query SiteMetadata {
            site {
                siteMetadata {
                siteUrl
                title
                description
                }
            }
        }
    `)
    //have to make sure that we don't show the page title on the root 
    const defaultTitle = pagetitle !== "" ? `${pagetitle} | ${title}` : `${title}`

    return (
        <Helmet defer={false} defaultTitle={defaultTitle} titleTemplate={`%s | ${pagetitle}`}>
            <html lang="en" />
            <link rel="canonical" href={`${siteUrl}${pathname}`} />
            <meta name="docsearch:version" content="2.0" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
            />

            <link rel="icon" type="image/png" sizes="16x16" href={`${favicon16}`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32}`} />
            <link rel="shortcut icon" type="image/png" href={`${favicon64}`} />

            <meta property="og:url" content={siteUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en" />
            <meta property="og:site_name" content={title} />
            {/* <meta property="og:image" content={`${siteUrl}${gatsbyIcon}`} /> */}
            {/* <meta property="og:image:width" content="512" /> */}
            {/* <meta property="og:image:height" content="512" /> */}

        </Helmet>
    )
}

export default SiteMetadata